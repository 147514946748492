import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './Pages/Home';
import EditOrderPage from './Pages/Home/EditOrder';
import LoginPage from './Pages/Login';
import PrivateRoute from './RoutesPrivate'
import IndicatorsPage from './Pages/Indicators';
import CajaPage from './Pages/Caja';
import StepOnePage from './Pages/Home/CreateOrder/stepone.page';
import StepTwoPage from './Pages/Home/CreateOrder/steptwo.page';
import StepThreePage from './Pages/Home/CreateOrder/stepthree.page';
import RoutesPrivate from './RoutesPrivate';
import ProfilePage from './Pages/Profile';
import ConfigurationPage from './Pages/Configuration';
import ClientPage from './Pages/Clients';
import PaymentPage from './Pages/Payment';
import TurnPage from './Pages/Turn';
import CartPage from './Pages/Cart';
import ParkingPage from './Pages/Parking';
import CreateTurnPage from './Pages/Turn/create.turn';
import EditClientPage from './Pages/Clients/edit.client';
import VehicleClientPage from './Pages/Clients/vehicle.client';
import CotizationPage from './Pages/Cotization';
import CreateCotizationPage from './Pages/Cotization/stepone.cotization';
import StepOneCotization from './Pages/Cotization/stepone.cotization';
import steptwoCotization from './Pages/Cotization/steptwo.cotization';
import ExpensePage from './Pages/Expense';
import CartProductsPage from './Pages/Cart/cart.products';
import CartTakePage from './Pages/Cart/cart.take';
import CartPaymentPage from './Pages/Cart/cart.payment';
import CartHistoryPage from './Pages/Cart/cart.history';
import CajaWorkerPage from './Pages/Caja/caja.worker';
import DetailTurnPage from './Pages/Turn/detail.turn';
import EditCCPage from './Pages/Home/EditOrder/edit.cc';
import SearchPlatePage from './Pages/Home/search_plate';
import SearchClientPage from './Pages/Home/search_client';
import CreateClientPage from './Pages/Clients/create.client';
import CartInventoryPage from './Pages/Cart/cart.inventory';
import cartInventoryDetail from './Pages/Cart/cart.inventory.detail';

const Routes = () => {
    return (
        <Switch>
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute exact path="/step-one" component={StepOnePage} />
            <PrivateRoute exact path="/search-plate" component={SearchPlatePage} />
            <PrivateRoute exact path="/search-client" component={SearchClientPage} />
            <PrivateRoute exact path="/step-two" component={StepTwoPage} />
            <PrivateRoute exact path="/step-three" component={StepThreePage} />
            <PrivateRoute exact path="/order/:id" component={EditOrderPage} />
            <PrivateRoute exact path="/order-cc/:id" component={EditCCPage} />
            <Route exact path="/login" component={LoginPage} />
            <RoutesPrivate exact path="/indicadores" component={IndicatorsPage} />
            <RoutesPrivate exact path="/caja" component={CajaPage} />
            <RoutesPrivate exact path="/caja-worker/:worker_id" component={CajaWorkerPage} />
            <RoutesPrivate exact path="/clients" component={ClientPage} />
            <RoutesPrivate exact path="/clients-create" component={CreateClientPage} />
            <RoutesPrivate exact path="/clients/:id" component={EditClientPage} />
            <RoutesPrivate exact path="/clients-vehicle/:id" component={VehicleClientPage} />
            <RoutesPrivate exact path="/perfil" component={ProfilePage} />
            <RoutesPrivate exact path="/configuracion" component={ConfigurationPage} />
            <RoutesPrivate exact path="/payment/:id" component={PaymentPage} />
            <RoutesPrivate exact path="/turns" component={TurnPage} />
            <RoutesPrivate exact path="/turns/:id" component={DetailTurnPage} />
            <RoutesPrivate exact path="/open-turn" component={CreateTurnPage} />
            <RoutesPrivate exact path="/carrito" component={CartPage} />
            <RoutesPrivate exact path="/parqueaderos" component={ParkingPage} />
            <RoutesPrivate exact path="/cotizaciones" component={CotizationPage} />
            <RoutesPrivate exact path="/stepone-cotization" component={StepOneCotization} />
            <RoutesPrivate exact path="/steptwo-cotization" component={steptwoCotization} />
            <RoutesPrivate exact path="/egresos" component={ExpensePage} />
            <RoutesPrivate exact path="/cart-products" component={CartProductsPage} />
            <RoutesPrivate exact path="/cart-take" component={CartTakePage} />
            <RoutesPrivate exact path="/cart-payment" component={CartPaymentPage} />
            <RoutesPrivate exact path="/cart-history" component={CartHistoryPage} />
            <RoutesPrivate exact path="/cart-inventory" component={CartInventoryPage} />
            <RoutesPrivate exact path="/cart-inventory-detail/:product_id" component={cartInventoryDetail} />
        </Switch >
    )
}

export default Routes;